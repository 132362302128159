@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700;900&family=Roboto:wght@400;700;900&family=Source+Sans+3:wght@200;300;400&display=swap');
a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: 'Fira Sans', Arial, Helvetica, sans-serif !important;
}
